@import '../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../assets/style/base/designTheme.scss';
@import '../../../../../style/globalStyles.scss';
@import '../../variables';

$dropzoneHeight: 550px;
$uploadProjectZone: 574px;
$uploadUnitHeight: 96px;
$uploadErrorHeight: 156px;
$unitHeight: 75px;
$unitErrorHeight: 140px;
$warningMessageBck: #f2f2f2;

.new-upload-project {
	&__files {
		padding: 0 15px 10px;
		min-width: 537px;
		justify-content: space-between;
		min-height: 700px;

		@include breakpoint(custom, 1960px, 1080px) {
			min-height: 900px;
		}

		.files-upload {
			&-content {
				position: relative;

				&.disabled {
					.files-upload-content__icon {
						filter: grayscale(1);
						opacity: 0.5;

						&:hover {
							cursor: no-drop;
						}
					}

					.files-upload-content__text,
					.files-upload-content__title {
						&,
						h1 {
							color: $darkGrey;
						}

						svg {
							&,
							path {
								fill: $darkGrey;

								&#white {
									fill: white;
								}
							}
						}
					}
				}

				&__icon {
					width: $upload-icon-size;
					height: $upload-icon-size;

					@include breakpoint(custom, 1960px, 1080px) {
						width: 75px;
						height: 60px;
					}

					&:hover {
						cursor: pointer;
					}

					path {
						fill: url(#svg-gradient);
					}
				}

				&__text,
				&__title {
					color: $darkTextColor;
					text-align: center;
				}

				&__text {
					//padding: 0 20px;
					height: 30px;
					margin: 0 0 16px;
					@include font-builder(
						$smallFont,
						$mediumFontWeight,
						$basicFontHeight
					);

					@include breakpoint(custom, 1960px, 1080px) {
						@include font-builder(13px, $mediumFontWeight, $basicFontHeight);
					}
				}

				&__title {
					display: flex;

					h1 {
						margin-left: 8px;
						margin-top: 16px;
						margin-bottom: 12px;
						@include font-builder(
							$largeFont,
							$largeFontWeight,
							$largeFontHeight
						);

						&:hover {
							cursor: pointer;
							text-decoration: underline;
						}
					}

					svg {
						font-size: 20px;
						width: 15px;
						height: 20px;
						margin-left: 3px;

						&,
						path {
							fill: $darkTextColor;
						}
					}
				}

				&__dropzone {
					margin-top: 40px;
					height: 100%;
					@include flex(center, space-between);

					&:hover {
						.upload-project-dropzone {
							cursor: pointer;
							box-shadow: 0 3px 3px #efefef;
						}
					}

					&.disabled {
						pointer-events: none;

						.upload-project-dropzone {
							border: none;
							height: 50vh;
							max-height: 670px;

							@include breakpoint(custom, 1960px, 1080px) {
								height: $dropzoneHeight;
							}
						}
					}

					.upload-project-dropzone {
						position: relative;
						display: flex;
						overflow: hidden;
						margin: 10px;
						border: 1px dashed $whiteColor;
						background: linear-gradient($whiteColor, $whiteColor) padding-box,
							linear-gradient(92.35deg, $darkGrey 1.28%, $darkGrey 98.95%)
								border-box;
						border-radius: 8px;
						width: 473px;
						height: 53vh;
						max-height: 670px;
						min-height: 475px;
						padding: 12px;

						&.without-type {
							@include breakpoint(custom, 1960px, 1080px) {
								height: 530px;
							}
						}

						@include breakpoint(custom, 1960px, 1080px) {
							width: 473px;
							height: $uploadProjectZone;
							padding: 20px;
							margin: 20px;
						}

						&:hover,
						&.drag-on {
							border-color: $lightBlue;
							background: repeating-linear-gradient(
								45deg,
								rgba(32, 192, 230, 0.03),
								rgba(32, 192, 230, 0.03) 10px,
								rgba(32, 192, 230, 0.01) 10px,
								rgba(32, 192, 230, 0.01) 20px
							);
						}

						&.drag {
							&-off {
								border-color: $darkGrey;
							}
						}

						& > div {
							width: 100%;
						}
					}

					.upload-unit-type {
						margin: -10px -10px 5px -10px;
						padding: 9px 20px 9px 37px;
						background: $whiteBackground;
						border-radius: 8px 8px 0 0;

						@include breakpoint(custom, 1960px, 1080px) {
							margin: -20px -20px 16px -20px;
							padding: 9px 20px 9px 37px;
						}

						&.with-unit {
							height: $uploadUnitHeight - 10px;

							@include breakpoint(custom, 1960px, 1080px) {
								height: $uploadUnitHeight;
							}
						}

						.field-with-label {
							&.without-label {
								&.outlined {
									&,
									div {
										@include font-builder($smallFont, $mediumFontWeight, 17px);
									}
								}
							}
						}

						.upload-project--unit-type-title {
							margin: 0;
							@include font-builder(
								$smallFont,
								$mediumFontWeight,
								$smallFontHeight
							);
							color: $darkTextColor;
							position: relative;

							svg {
								position: absolute;
								left: -20px;
								top: 2px;
							}
						}
					}

					.upload-list {
						height: 100%;
						overflow-y: auto;
						overflow-x: hidden;
						@include styled-scroll();

						@include breakpoint(1860) {
							padding-top: 2px;
						}

						&.with-unit {
							max-height: calc(100% - #{$unitHeight - 5px});

							@include breakpoint(1860) {
								max-height: calc(100% - #{$unitHeight});
							}
						}
					}

					.upload-files {
						padding: 9px;
						margin-bottom: 15px;
						margin-top: 1px;
						margin-left: 1px;
						@include flex(center, flex-start, column);

						&.grey {
							background: $whiteBackground;
							border: 1px solid #dedede;
							outline: 1px solid #dedede;
							border-radius: 8px;

							& > div {
								.text {
									color: #848484;
								}

								svg {
									path {
										fill: #848484;
									}
								}
							}
						}

						& > div {
							width: 100%;
							@include flex(center, space-between, row);

							.text {
								@include typography();
								@include font-builder(
									$smallFont,
									$mediumFontWeight,
									$smallFontHeight
								);
								margin-left: 8px;
								width: 100%;
							}
						}

						svg {
							position: relative;
							top: initial;
							left: initial;
							right: initial;

							* {
								fill: $select-box-selected-color;
							}
						}
					}
				}
			}
		}

		.upload-button {
			height: 46px;

			.details-popup-target {
				button {
					margin: 0;
				}
			}

			& > * {
				margin: 0;
			}
		}

		.files-upload-content__message {
			position: absolute;
			width: 330px;
			text-align: center;
			top: 110px;
			padding: 10px 20px;
			border-radius: 12px;
			background-color: rgba($warningMessageBck, 0.8);
			box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1),
				0 3px 1px -2px rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);

			.close-icon {
				svg {
					&:hover {
						cursor: pointer;
						scale: 1.1;
					}
				}
			}

			.upload-warning {
				svg {
					width: 165px;
					height: 170px;
				}
			}

			button {
				padding: 10px;
				margin-top: 25px;
			}
		}

		.use-our-part-button {
			background-color: $button-secondary;
			border: 1px solid $lightBlue;
			border-radius: 8px;
			height: 46px;
			top: 1px;
			width: 287px;
			margin-left: 6px;

			span {
				color: $lightBlue;
			}
			& > * {
				margin: 0;
			}

			&-label {
				color: $text-regular-opac;
			}
		}

		.upload-button-area-light-user {
			width: 487px;
			gap: 8px;
		}

		.warn-message {
			margin-top: 12px;
			margin-bottom: 0;
			justify-content: flex-start;
		}
	}

	&__agreement {
		margin-top: 10px;

		.upload-terms-of-agreement {
			> div {
				@include flex(center, flex-start);

				.agreement-button {
					margin-left: 5px;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					text-decoration-line: underline;
					text-transform: uppercase;
					color: $select-box-selected-color;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	.upload-terms-of-agreement {
		@include flex(center, flex-start);

		p {
			margin: 0;
		}
	}
}
