@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

.admin-tm-technology-params {
	th:last-of-type {
		text-align: right;
		width: 160px;
		text-transform: capitalize;
	}
	.admin-data-table-actions {
		width: 160px;
		@include flex(center, flex-end);
	}

	.transparent-button {
		text-transform: none;
	}

	a {
		margin-left: 10px;
		padding-right: 10px;

		&:hover {
			button {
				scale: 1.1;
				transition: all 0.3s;
			}

			svg {
				path {
					fill: darken($transparent-button-primary, 15);
				}
			}
		}

		button {
			padding: 0;

			svg {
				width: 20px;
			}
		}
	}

	.admin-data-table-actions {
		position: relative;
		right: 20px;
	}
}
