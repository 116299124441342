@import '../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../assets/style/base/designTheme.scss';
@import '../../../../../style/globalStyles.scss';

.solution-analysis-object {
	position: relative;
	margin-bottom: 29px;
	height: 202px;
	width: 100%;
	border-radius: 3px;
	//box-shadow: 0 2px 7px 0 hsla(0, 0%, 0%, 0.16);
	align-self: center;
	border-top: 5px solid;
	border-width: $object-content-border-width;
	border-radius: $object-content-border-radius;
	transition: all 0.2s ease-out;
	background-color: $object-content-background;
	z-index: $app_z_index;

	.details-popup-target {
		&:not(.regular-style-target) {
			visibility: hidden;
			margin-bottom: 10px;
		}

		&.light-user {
			margin-bottom: 0;
		}
	}

	&.disabled {
		background-color: hsla(0, 0%, 13%, 0.1);
		pointer-events: none;

		*:not(.button):not(button) {
			background-color: transparent;
		}
	}

	&--threeDViewer--footer {
		position: absolute;
		bottom: 10px;
		width: 96%;

		&--index {
			width: 70%;
		}
	}

	&:hover {
		box-shadow: 0px 0px 10px 10px hsla(0, 0%, 0%, 0.16);

		&:not(.expand) {
			background-color: hsla(0, 0%, 13%, 0.1);
		}

		.solution-header--see-more {
			opacity: 1;

			&.hide {
				opacity: 0;
			}
		}
	}

	&-top {
		cursor: pointer;
	}

	&-footer {
		padding: 16px;

		button {
			margin: 0;
			margin-left: 10px;
		}
	}

	&.new {
		&#{&} {
			height: 0;
			opacity: 0;
			visibility: hidden;
			margin-bottom: 0;
			z-index: -1;
			transition: all 0.2s ease-in-out;
			overflow: hidden;
		}
	}

	&-top-details {
		pointer-events: none;
		.castor-detail-body {
			.lead {
				white-space: initial !important;
				text-align: left;
			}
		}
		&.expand {
			pointer-events: unset;
		}
	}

	&:not(.expand) {
		.solution-analysis-header-buttons-wrapper {
			pointer-events: none;
		}
	}

	&.expand {
		height: $expand-config-height; //transition doesn't work with height:auto
		transition: all 0.2s ease-in-out;

		.details-popup-target {
			visibility: visible;

			&.light-user {
				margin-bottom: 0;
			}
		}

		&.footer {
			height: $big-expand-config-height;
		}

		&.without-ribbon-info {
			.solution-analysis-header-chevron {
				display: none;
			}
		}

		&.challenges-info {
			height: 400px !important;
		}

		.select-drop-down {
			pointer-events: unset;

			&--icon {
				opacity: 1;
			}
		}

		.solution-analysis-object-content {
			opacity: 1;
			visibility: visible;
			transition: all 0.2s ease-in-out;
			z-index: 2;
			margin: $solution-analysis-object-content-margin;
			min-height: 400px;

			&.show-pdf-viewer {
				z-index: 4;
				transition: unset;
			}

			&.with-footer {
				margin-bottom: 0;
			}

			&-configure {
				opacity: 0;
				visibility: hidden;
				z-index: -1;

				&--transparent-button {
					text-align: left;
				}

				&--tolerance-button {
					text-transform: unset;
				}

				&-top-header {
					margin-bottom: 5px;
					align-items: flex-start !important;
				}
			}

			&-details {
				opacity: 1;
				visibility: visible;
				z-index: 2;
			}

			&.show-configure {
				.solution-analysis-object-content-configure {
					opacity: 1;
					visibility: visible;
					z-index: 2;
				}

				.solution-analysis-object-content-details {
					opacity: 0;
					visibility: hidden;
					z-index: -1;
				}
			}
		}
	}

	.part-file-name {
		text-align: left;
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	&-content {
		opacity: 0;
		visibility: hidden;
		margin: 0 35px;
		z-index: -1;
		transition: all 0.2s ease-in-out;
		position: relative;

		&-tabs {
			position: relative;
			width: calc(100% - 500px);
			min-width: calc(100% - 500px);
		}

		&-details {
			opacity: 0;
			visibility: hidden;
			z-index: -1;
			transition: all 0.2s ease-in-out;
		}

		&-configure {
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;
			z-index: -2;
			transition: all 0.2s ease-in-out;

			&--top {
				z-index: 1;
			}

			&-widget {
				width: 40%;
				height: 100%;
				display: flex;
				align-items: center;
				flex-direction: column;

				&--inputs {
					@include flexible-column();
					width: 100%;
					overflow: auto;
					max-height: 225px;
					height: 100%;
					background-clip: text;
					transition: background-color 0.5s ease-in-out;

					&::-webkit-scrollbar {
						width: 7px;
						border-radius: 10px;
						background-color: hsla(218, 76, 15, 0.08);
					}

					&::-webkit-scrollbar-thumb {
						background-color: hsla(0, 0, 66, 0.7);
						border-radius: 10px;
					}
				}

				&--footer {
					flex-direction: column;
					align-items: flex-end;
					width: 100%;
					position: absolute;
					bottom: 0;
				}

				&-filters {
					width: 35% !important;
				}

				&-devider {
					margin: 15px 0;
					width: 105% !important;
					align-self: center;
				}

				&-remove-icon {
					color: $text-regular-opac;
					width: 20px !important;
					height: 20px !important;
				}

				&-romeve_button {
					right: -26px;
					top: 1px;
					position: absolute !important;
				}

				&-field {
					width: 100%;
					font-size: 18px;
				}

				&-toggle {
					margin: 16px 0 0;
					width: 100%;
					position: relative;
					text-align: left;
				}

				&--text-field {
					margin: 21px 0 0;
				}

				&-title {
					font-size: 18px;
					font-weight: bold;
					color: $text-regular;
					text-align: left;

					&--with-info {
						@include flex(flex-start, flex-start);
						width: 105%;
						gap: 5px;

						svg {
							width: 14px;
							height: 14px;
							cursor: pointer;
							margin-left: 5px;
						}
					}
				}

				&--popper {
					background-color: $long-text-read-more-background !important;
					text-align: left;

					&--data {
						font-size: 14px !important;
						line-height: 1.44 !important;
						color: $text-regular-opac !important;
					}
				}

				&-feature {
					font-size: 16px;
					margin-left: 3px;
					text-align: right;

					&-modal {
						display: block !important;
						padding: 0 !important;
						width: 527px !important;

						h2 {
							margin: 0;
						}
					}
				}
			}

			&-filters {
				display: flex;
				justify-content: flex-start;
				flex-flow: column;
				align-items: flex-start;
				width: 90%;
				height: 245px;
				overflow: auto;

				p {
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
				}

				svg {
					margin-right: 6px;
				}
			}
		}
	}
}

.solution-analysis-header {
	align-items: center;
	justify-content: space-between;
	background-color: $object-header-background;
	height: 61px;
	padding: 0 37px 0 26px;

	&-disable-link {
		pointer-events: none;
	}

	&-text {
		$textClass: &;
		font-size: 17px !important;
		//color: $text-light-opac !important;
		margin: 0 2px !important;
		opacity: unset !important;
		padding: 4px 14px !important;
		transition: all 0.2s ease-in-out !important;
		border: solid 2px transparent !important;

		&.upper {
			text-transform: uppercase;
		}

		&.with-border.inside-link {
			height: 100%;
		}

		&.space {
			margin-right: 10px !important;
		}

		&.enabled {
			z-index: 0;
			//color: $text-white;
			transition: all 0.2s ease-in-out;

			&:hover {
				//color: var(--solution-color);
				&.with-border {
					box-shadow: 0px 0px 1px 1px var(--solution-color);
				}
			}

			&.with-border {
				z-index: 1;
				//color: var(--solution-color);
				border: solid 2px var(--solution-color);

				#{$textClass}-icon {
					fill: var(--solution-color);
					stroke: var(--solution-color);
				}
			}

			&.filled {
				//color: $object-header-background;
				background-color: var(--solution-color);

				#{$textClass}-icon {
					fill: $object-header-background;
					stroke: $object-header-background;
				}
			}

			&.active,
			&:hover {
				&.filled {
					box-shadow: 0px 0px 1px 1px var(--solution-color);
				}

				&.filled #{$textClass}-icon {
					fill: $object-header-background;
					stroke: $object-header-background;
				}
			}
		}

		&.enabled &-icon {
			fill: $text-white;
			stroke: $text-white;
			transition: all 0.2s ease-in;
		}

		&.enabled.active &-icon {
			fill: var(--solution-color);
			stroke: var(--solution-color);
		}

		&.enabled:hover &-icon {
			fill: var(--solution-color);
			stroke: var(--solution-color);
		}

		&.saved {
			color: var(--solution-color);

			.solution-analysis-header-text-icon {
				stroke: var(--solution-color);
			}
		}

		&-icon {
			fill: $text-light-opac;
			stroke: $text-light-opac;
			transition: all 0.2s ease-in-out;
			width: 20px;
			height: 20px;
			margin-right: 5px;

			&.without-stroke {
				stroke: none;
				width: 16px;
				height: 16px;
				top: 3px;
				left: 3px;
				position: relative;
			}

			#combine-fill {
				fill: $combineIconFill;
			}
		}
	}

	&-wrapper {
		z-index: 1;

		button {
			display: $object-header-show-icon;

			svg {
				fill: $button-primary;
			}
		}
	}

	&-chevron {
		transition: all 0.2s ease-in-out !important;

		&-rotate {
			transform: rotate(90deg);
		}
	}

	&-best-match {
		display: flex;
		align-items: center;
		font-size: 26px;
		max-width: 30%;

		&-chevron {
			width: 50px;
			height: 50px;
			transition: all 0.2s ease-in-out !important;
		}
	}

	&-buttons-wrapper {
		z-index: 3;
	}

	&-name {
		input {
			padding: 4px 8px;
			text-overflow: ellipsis;
			font-size: 26px;
			height: 40px;
			border: none;
			max-width: $object-header-name-width;
			transition: box-shadow 0.2s ease-in-out !important;
			background: transparent;
			text-transform: capitalize;
		}

		&--desktop input {
			max-width: 400px;
		}
	}

	&-name {
		&.edit input {
			background: initial;
			box-shadow: inset 0 -2px 0;
		}
	}

	&-wrapper {
		display: inline-flex;
		align-items: center;
	}

	&-benefits {
		width: 100%;
		border-radius: 16px;
		display: inline-flex;
		align-items: center;
	}
}

.financial-form-datapoint-hover {
	background-color: $button-primary;
	width: 295px;
	white-space: normal;
	padding: 12px;
	font-weight: bold;
	text-align: left;
	color: $textOnSuccessBackground;

	&-cost {
		font-size: initial;
	}
}

.main-content-scroller {
	scroll-behavior: smooth;
	height: calc(100vh - #{$space-for-scroller});
	overflow: scroll !important; // scrollTo change this value

	&.is-weight-reduction-page {
		overflow: hidden !important;
	}

	&.is-demo-user {
		height: 100vh;
	}

	&.with-banner {
		height: calc(100vh - #{$space-for-scroller} - #{$space-for-banner});
	}

	@include styled-scroll();

	.configuration-block {
		max-width: $configuration-block-max-width;
		min-width: 1200px;
		padding: 0 10px 10px;
		margin: $configuration-block-margin;
	}
}
