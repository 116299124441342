@import '../../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../../style/globalStyles.scss';
@import '../../../../../../../assets/style/base/designTheme.scss';

.part-image-container {
	&#{&} {
		width: 100%;
		height: $part-image-size;
		max-width: 500px;
		margin: auto;
	}

	&-div {
		height: 100%;
		position: absolute;
		width: 100%;
	}

	.pdf-file {
		position: relative;
		width: 100%;
		height: 100%;
		cursor: pointer;
		overflow: hidden;

		> div {
			width: 100%;
			height: 100%;

			.part-image-container-img {
				width: auto;
				height: auto;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	&-img {
		height: 100%;
		cursor: pointer;
		width: 100%;
		border-radius: 6px;
		object-fit: cover;

		&-orientation {
			height: 89%;
		}
	}

	&-coordinate {
		position: absolute;
		z-index: 100;
		right: 11px;
		top: 7px;
		width: 80px;
	}

	&--image-content {
		position: relative;
	}

	&--bottom-buttons-wrapper {
		&#{&} {
			position: absolute;
			bottom: 0;
		}
	}

	&--top-buttons-wrapper {
		&#{&} {
			position: absolute;
			top: 0;
		}
	}

	&--orientation {
		&#{&} {
			opacity: 0;
			transition: opacity 0.3s ease-in-out;
			pointer-events: none;
			max-width: 100%;
			max-height: 100%;
		}

		&.show {
			pointer-events: unset;
			opacity: 1;
		}
	}

	&--part-size {
		&#{&} {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 100;
			height: 31px;
			margin: 8px 10px;
			&.cluster {
				top: 60px;
			}
		}

		.info-box-icon {
			&.with-color {
				fill: $orientation-icon-color;
			}
		}
	}

	&--structural {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		pointer-events: none;

		&.show {
			pointer-events: unset;
			opacity: 1;

			&:has(> button:only-child) {
				position: absolute;
				right: 0;
				width: auto;
			}

			.threeD-button {
				&:hover {
					svg {
						&,
						g,
						path {
							fill: $button-hover-color;
							stroke: $button-hover-color;
						}
					}
				}
			}
		}
	}
}

.part-analysis-heat_map-button {
	top: 74%;
	left: 0%;
	font-size: 16px;
	color: black;
}

.part-detail-body {
	font-size: 16px;
}

.part-details-devider {
	color: #dedede;
	width: 1px !important;
	margin: 0 7px;
}

.threeD-button {
	width: 77px;
	height: 31px;
	margin: 7px 10px !important;
	padding: 4px 10px !important;
}
.pdf-button {
	svg {
		margin: 0 2px 0 0;
	}
}

.orientation-button {
	&#{&} {
		bottom: 0;
		right: 0;
		height: 31px;
		margin: 8px 10px;
		padding: 4px 10px;
	}
}

.tab-buttons {
	&#{&} {
		background: transparent;
		color: $text-regular-opac;
		box-shadow: none;
		padding: 0px;
		margin: 0px;
		width: 100%;
		height: 100%;
		border-radius: unset;
		border-bottom: solid transparent;
	}

	&:hover {
		&#{&} {
			background: transparent;
			color: $button-primary;
			box-shadow: unset;
		}
	}

	&--chosen {
		&#{&} {
			color: $button-primary;
			border-color: $button-primary;
		}
	}
}

.tabs-wrapper {
	border: solid 1.2px rgba(132, 132, 132, 0.23);
	width: 100%;
	display: flex;
	flex-direction: row;
	border-radius: 6px 6px 0px 0px;
	border-bottom: none;
	height: 50px;
}

.tab {
	margin-right: 120px;
	margin-left: 8px;

	&-chosen {
		&#{&} {
			border-bottom-width: medium;
			border-bottom: solid 1.2px $button-primary;
		}
	}
}

.threeD-button-lable {
	margin: 0px 5px;
}

.suggestion-slider {
	border: none;

	.slider_section-header {
		position: absolute;
		z-index: 11;
		top: 0;
		right: 0;
		text-align: center;
		border: 0;
		background-color: transparent;
		border-radius: 4px;
		padding: 5px;

		@include flex(center, center);
		> div {
			font-weight: 400;
			font-size: 14px;
			color: white;
			background-color: rgba(black, 0.3);
			padding: 5px 10px;
			border-radius: 4px;
			@include flex(center, center);
		}
	}

	.slider_section--main {
		height: 100%;
	}

	.slick-dots {
		bottom: 7px;
		z-index: 10;
		width: auto;
		left: 50%;
		transform: translateX(-50%);

		li {
			width: 15px;
			margin: 0;

			button {
				padding: 0;
			}
		}
	}

	.slider_section {
		width: 483px;
		border: none;
		border-radius: 4px;
		overflow: hidden;
	}

	&.project-results--block.with-image {
		width: 485px;
		height: 400px !important;
		max-width: none !important;
	}
}
