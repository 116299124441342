$windowWidth: 720px;

.upload-script {
	align-self: center;
	text-align: left;

	.main-content-scroller {
		min-width: $windowWidth;
		max-width: $windowWidth;
		margin: auto;
	}

	.date-range-picker-wrapper {
		display: flex;
		justify-content: space-between;
		margin: 10px 0 !important;
		margin: 8px 8px;
		div,
		input {
			width: 100%;
		}
	}
	.radio-button {
		display: grid;
		padding-top: 4px;
		&--label {
			&#{&} {
				align-items: center;
			}
		}
	}

	.radio-and-field {
		display: flex;
		justify-content: space-between;
		width: 100%;

		&-field {
			width: 50%;
		}

		&-checkbox {
			width: 50%;
			padding-top: 3%;
		}
	}

	.sub-section {
		padding-left: 50px;
	}

	&-body {
		margin-top: 10px;
	}

	&-error {
		color: #e74c3c;
	}

	&-button {
		width: 100%;
		margin: 2px 0 0;
		height: unset;
	}

	&-text {
		width: $windowWidth;
		align-self: center;
		align-items: flex-start;
	}

	&-form {
		margin: 1em;
		display: flex;
		flex-direction: column;
	}

	&-field {
		width: 100%;
		margin: 0.5em 0 !important;
		&.password {
			input {
				text-security: disc;
				-webkit-text-security: disc;
				-mox-text-security: disc;
			}
		}
	}

	&-dropzoneDrag,
	&-dropzone {
		max-width: $windowWidth;
		cursor: pointer;
		height: 160px;
		justify-content: center;
		align-items: center;
		background-image: url('../../../../assets/img/dropzoneBG.png');
	}

	&-dropzoneDrag {
		border: 5px solid green;
	}

	&-dropzone {
		&__flex {
			height: 100%;
			width: 100%;
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		&__icon {
			margin: 8px;
			transform: scale(1.5);
		}
	}
}

.with-error-absolute {
	vertical-align: top;
	min-height: 60px;

	div + p {
		position: absolute;
		bottom: -10px;
		color: #f44336;
	}
}
