@import '../../../style/globalStyles.scss';
@import '../../../assets/style/base/colorsTheme.scss';

.login-screen {
	z-index: 111;

	@media only screen and (max-width: 900px) {
		width: 100vw;
	}

	&--input {
		margin-top: 27px !important;
	}
}

.login-version-text {
	position: absolute;
	bottom: 10px;
	right: 2em;
	font-size: 0.8em;
	opacity: 0.6;
	color: white;
	z-index: 1;
}

.terms-block {
	margin: 0 0 0 12px;

	&.animated-terms {
		svg {
			color: $warnColor !important;
			border-color: $warnColor;
			transition: background-color 0.5s ease-in-out;
			animation: lighten-bg 0.8s infinite alternate;

			@keyframes lighten-bg {
				from {
					background-color: rgba($warnColor, 0.1);
				}
				to {
					background-color: rgba($warnColor, 0.2);
				}
			}
		}
	}
}

.register-text {
	color: gray;
	font-size: 1.1em;
	@include flex(center, center);

	&.providers {
		margin-top: 20px;
	}
	p {
		margin: 0 8px 0 0 !important;
	}

	.link,
	a {
		color: $button-primary;
		transition: all 0.2s ease-out;

		&:hover {
			cursor: pointer;
			color: darken($button-primary, 20);
		}
	}
}

.register-text-link {
	color: gray;
	text-align: right;
	margin-top: 10px;
	transition: all 0.2s ease-out;

	&:hover {
		cursor: pointer;
		color: darken(gray, 20);
	}
}

.login-button {
	width: 100%;
	height: 48px !important;
}

.req-email input {
	margin: auto;
	background-image: gray !important;
}

.login-powered-by {
	align-self: center;
	margin-top: 40px;
	width: 100%;

	.poweredBy--text {
		margin-top: 5px;
		margin-right: 7px;
	}
}

.register-powered-by {
	margin: auto;
	width: 155px;
}

.login-providers {
	@include flex(center, center, column);

	span {
		text-transform: inherit;

		.provider {
			text-transform: capitalize;
		}
	}

	div {
		margin: 0 5px;
		width: 100%;

		+ div {
			margin-top: 10px !important;
		}

		&:hover {
			cursor: pointer;
		}
	}

	button {
		margin: 0 !important;
		padding: 7px 20px !important;
	}

	img {
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}

	&_block {
		.terms-block {
			margin-left: 0;
		}
	}

	&_title {
		position: relative;
		height: 30px;

		.text {
			position: absolute;
			font-size: 14px;
			background-color: white;
			top: -15px;
			right: 46%;
			padding: 6px;
		}

		.line {
			width: 100%;
			height: 1px;
			background-color: gray;
		}
	}
}
.register-form {
	&--organization-input {
		text-align: left;
		margin: 0 12px !important;
		width: 100%;
		padding-bottom: 10px !important;
		label {
			color: #b1b1b1;
			left: 43px;
			font-weight: 100;
		}

		svg {
			color: #555;
		}

		input {
			padding-left: 18px;
		}

		> div {
			> div {
				padding-left: 18px;
			}

			&:before {
				border-color: #d2d2d2;
			}
		}
	}
}
