@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

.specific-tolerances {
	&--alert-body {
		padding: 30px;
		h3 {
			font-weight: 500;
			margin-top: 0;
		}
		.gd-tabs {
			@include flex(flex-start, flex-start, row);
			width: calc(100% - 50px);
			margin: 0 20px;
			position: relative;
			z-index: 1;

			&_item {
				padding: 12px;
				background-color: $background;
				font-size: 14px;
				font-weight: 400;
				border: 2px solid $background;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				margin-right: 8px;
				position: relative;

				&:hover {
					cursor: pointer;
					color: $lightBlue;
				}

				&.disabled {
					pointer-events: none;
					background-color: darken(white, 10);
					border-color: darken(white, 10);
				}

				&.active {
					color: $lightBlue;
					background-color: white;
					border-color: $lightBlue;
					font-weight: 500;

					&:after {
						content: '';
						position: absolute;
						width: 100%;
						height: 8px;
						background-color: white;
						bottom: -7px;
						left: 0;
						z-index: 1;
					}
				}
			}
			&_content {
				box-shadow: 0 0 10px $cardShadow;
				width: 100%;
				margin: 0;
				padding: 0 15px 15px;
				z-index: 0;
				overflow: visible;
				border-top: 2px solid $lightBlue;
				transition: all ease-in 500ms;
				border-radius: 4px;
				min-height: 100%;
			}
		}
	}
	&--wrapper {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-columns: 40% 57%;
		gap: 3%;
	}
	&--subheader-section {
		width: 100%;
		min-height: 68px;
		display: grid;
		margin: 10px 0;
		grid-template-columns: 40% 57%;
		gap: 3%;
		&--part-name {
			@include flex(center, flex-start, row);
			color: $text-regular-opac;
			span {
				color: $text-regular;
				font-weight: 600;
				font-size: 16px;
				@include text-ellipsis();
				width: calc(100% - 100px);
				white-space: nowrap;
			}
		}
		&--customize-tolerance {
			@include flex(center, space-between, row);
			gap: 10px;
		}
	}
	&--threeD-viewer {
		box-shadow: 0 0 10px $cardShadow;
		border-radius: 8px;
		height: fit-content;
		position: relative;
		height: 550px;
		.geometry-analysis--content--part-display {
			margin: 0;
		}
		.geometry-analysis--content--part-display--viewer {
			border: none;
		}
	}
	&--pdf-viewer {
		box-shadow: 0 0 10px $cardShadow;
		border-radius: 8px;
		height: fit-content;
		position: relative;
		transform-style: preserve-3d;
		transition: all 0.8s ease-in;
		height: 550px;
		img {
			width: 100%;
			object-fit: contain;
		}
		&.img-view {
			transform: rotateY(180deg);
		}
		.pdf-preview--wrapp {
			backface-visibility: hidden;
			width: calc(100% + 1px);
			position: absolute;
			top: 0 !important;
			background-color: $whiteColor;
			height: 100%;
			border-radius: 8px;
			z-index: 900;
		}
		.fallback-wrapper {
			border-radius: 8px;
		}
	}

	&--group-header {
		@include flex(center, space-between, row);
		h5 {
			font-weight: 500;
		}
		button {
			margin: 5px 0 10px 0;
		}
	}
	&--general-tolerance {
		@include flex(center, flex-start, row);
		gap: 5px;
		color: $text-regular-opac;
		&--selector {
			width: 110px;
			flex-shrink: 0.1;
		}
	}
	&--group-wrapper {
		box-shadow: 0 0 10px $cardShadow;
		border-radius: 8px;
		padding: 5px 8px;
		margin-bottom: 10px;
		.error-placeholder {
			height: 21px;
		}
	}
	&--group-table {
		width: 100%;
		border-spacing: 0;
		margin-bottom: 10px;
		&.disabled {
			pointer-events: none;
		}
		&.not-active {
			filter: grayscale(100%) brightness(80%);
			pointer-events: none;
		}
		thead {
			th {
				font-weight: 400;
				color: $blackColor !important;
				padding: 0 5px;
				background-color: rgba(darken($button-primary, 5), 0.2) !important;
				border: 1px solid rgba(darken($button-primary, 5), 0.2) !important;
				vertical-align: middle !important;

				&:nth-child(1) {
					width: 50px;
					min-width: 50px;
				}
				&:nth-child(2) {
					width: 170px;
					min-width: 170px;
				}
				&:nth-child(5) {
					width: 150px;
					min-width: 150px;
				}

				@media screen and (max-width: 1370px) {
					&:nth-child(2),
					&:nth-child(5) {
						width: unset;
					}
				}
			}
		}
		tbody {
			td {
				height: 25px !important;
				border: none !important;
				border-bottom: 1px solid $cardShadow !important;
				border-right: 1px solid $cardShadow !important;
				input {
					height: 25px !important;
				}
				&:nth-child(1) {
					border-left: 1px solid $cardShadow !important;
					box-shadow: none !important;
					cursor: default !important;
				}
				&.read-only {
					background: unset !important;
					color: inherit !important;
				}
			}
		}
		&.holes {
			thead {
				th {
					&:nth-child(2) {
						width: 300px;
						min-width: 300px;
					}
				}
			}
		}
		&.threads {
			thead {
				th {
					&:nth-child(2) {
						width: 150px;
						min-width: 150px;
					}
					&:nth-child(3) {
						width: 150px;
						min-width: 150px;
					}
				}
			}
		}
	}
	&--tolerance-name {
		span {
			@include text-ellipsis;
			max-width: 100%;
			font-weight: 600;
		}
		@media screen and (max-width: 1370px) {
			flex-direction: column;
		}
	}
	&--tolerance-icon {
		@include flex(center, center, row);
		svg {
			width: 24px;
			height: 24px;
		}
	}
	&--tolerance-image {
		height: 100%;
		width: 100%;
		&--wrapper {
			@include flex(center, center, row);
			position: absolute;
			height: 100%;
			width: 100%;
			transform: rotateY(180deg);
			top: 0;
			right: 0;
			background-color: $whiteColor;
			border-radius: 8px;
		}
		&--inner-wrapper {
			position: relative;
			height: 100%;
			width: 100%;
		}
		&--close-btn {
			position: absolute;
			right: 10px;
			top: 10px;
			cursor: pointer;
			z-index: 1000;
		}
	}
	&--show-image-icon {
		cursor: pointer;
		width: 20px !important;
		height: 20px !important;
		&--wrapper {
			@include flex(center, center, row);
			height: 100%;
		}
		path {
			fill: $button-primary;
		}
	}
	&--sticky-container {
		position: sticky;
		top: 0;
		perspective: 1000px;
	}
}
