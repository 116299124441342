@import '../../../../../../style/globalStyles.scss';
@import '../../../../../../assets/style/base/colorsTheme.scss';
@import '../../../../../../assets/style/base/designTheme.scss';

.sidebar-poweredby {
	align-self: center;
	padding: 20px 0;
	margin-right: 10px;
	width: 100%;
	z-index: 4;

	&--logo {
		width: 115px !important;
	}
}

.sidebar {
	&--new-tag {
		position: absolute;
		top: 15px;
		right: 17px;
	}

	&--text {
		&#{&} {
			@include text-ellipsis;
			font-weight: $menu-font-weight;
		}
	}

	&-collapse {
		@include styled-scroll();
	}

	&--projects-toolbar {
		@include flex(flex-end, space-between, row);
		padding: 10px 25px;
		width: 100%;
		&--search-input {
			width: 80%;
			border-radius: 0;
			border: none;
			border-bottom: 1px solid $lightGrey;
			height: auto;
			> div {
				padding: 0;
			}
			input {
				margin-left: 0 !important;
				color: $object-header-text !important;
				font-size: 14px !important;
				&:-webkit-autofill {
					box-shadow: 0 0 0 1000px $sidebarDraggingItemBackground inset !important;
					-webkit-box-shadow: 0 0 0 1000px $sidebarDraggingItemBackground inset !important;
					-webkit-text-fill-color: $object-header-text !important;
				}
			}
			&.full-width {
				width: 100%;
			}
		}
		&--new-folder-icon {
			margin-right: 0 !important;
			cursor: pointer;
			width: auto !important;
			margin-left: -2px;
		}
		&--new-folder {
			@include flex(center, space-between, row);
			padding: 10px 25px;
			width: 100%;
			&--input {
				height: auto;
				padding: 7px 0;
				position: relative;

				&::after {
					content: '';
					width: 100%;
					position: absolute;
					height: 1px;
					background-color: $lightGrey;
					width: 85%;
					right: 20px;
					bottom: 0px;
				}

				& > div {
					color: $grey-search-bar;
					& > div {
						&:before,
						&:after {
							display: none;
						}
						input {
							color: $object-header-text;
							font-weight: 400;
							font-size: 14px;
							&::-webkit-search-cancel-button {
								display: none;
							}
							&:-webkit-autofill {
								box-shadow: 0 0 0 1000px $sidebarDraggingItemBackground inset !important;
								-webkit-box-shadow: 0 0 0 1000px $sidebarDraggingItemBackground
									inset !important;
								-webkit-text-fill-color: $object-header-text !important;
							}
						}
					}
				}
			}
			.clear-input {
				order: 2;
				cursor: pointer;
			}
			.confirm-input {
				order: 1;
				cursor: pointer;
				&.hidden {
					display: none;
				}
			}
		}
		&--edit-name {
			@include flex(center, space-between, row);
			padding-left: 25px;
			width: 100%;
			&--input {
				height: auto;
				width: 100%;
				& > div {
					color: $grey-search-bar;
					& > div {
						&:before,
						&:after {
							display: none;
						}
						input {
							color: $object-header-text;
							font-weight: 400;
							font-size: 14px;
							&::-webkit-search-cancel-button {
								display: none;
							}
							&:-webkit-autofill {
								box-shadow: 0 0 0 1000px $sidebarDraggingItemBackground inset !important;
								-webkit-box-shadow: 0 0 0 1000px $sidebarDraggingItemBackground
									inset !important;
								-webkit-text-fill-color: $object-header-text !important;
							}
						}
					}
				}
			}
			.clear-input {
				order: 2;
				cursor: pointer;
			}
			.confirm-input {
				order: 1;
				cursor: pointer;
				&.hidden {
					display: none;
				}
			}
		}
		&--wrapper {
			@include flex(flex-start, flex-start, column);
		}
	}
}

#sidebarWrapper {
	@include styled-scroll();

	@media only screen and (max-width: 900px) {
		top: 10px;
		height: 100vh;
	}

	.link-loader {
		width: 25px;
		padding-left: 5px;
	}

	div + .sidebar--text {
		padding-left: 7px !important;
	}

	.sidebar--text {
		padding-right: 5px;
	}

	a {
		width: 90%;
		transition: all ease-in 10ms;

		&.active {
			svg {
				path {
					fill: $object-header-text-focused;
				}
			}
		}

		&.top-nav-link {
			svg {
				path {
					fill: $sidebarIcon;
				}
			}
		}
	}

	svg {
		path {
			fill: $sidebarIcon;
		}
	}
	.header-links {
		margin-bottom: 40px;
	}
}

.hidden {
	display: none;
}

.sidebar-with-collapse {
	&.parts-sidebar {
		margin: 0;

		> a {
			&:has(+ div) {
				position: relative;
			}
		}

		&:not(.collapse-block) {
			.sidebar--text {
				padding: 0 !important;
				width: 100% !important;
				max-width: 100% !important;
			}
		}

		svg {
			display: none;
		}
	}

	.caret-block {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 0;
		top: 5px;

		&:hover {
			> b {
				scale: 1.5;
			}
		}

		> b {
			position: relative;
			margin: 0;
			right: -5px;
			top: -5px;
		}
	}
}

.collapse-block {
	&__list {
		height: auto !important;

		&.parts-list {
			&::before {
				content: '';
				position: absolute;
				left: 40px;
				width: 1px;
				top: -20px;
				height: 100%;
				background: $object-header-text;
			}

			a {
				margin-left: 50px;
				width: 80% !important;

				&::before {
					position: absolute;
					content: '';
					height: 1px;
					width: 10px;
					left: -10px;
					top: 20px;
					background: $object-header-text;
				}
			}
		}
	}
}

.is-dragging {
	box-shadow: none;
}

.with-projects-search-bar {
	height: calc(100vh - 80px) !important;
}
.sidebar--user-folder {
	padding: 0 !important;
	flex-direction: column;
	overflow: hidden;
	a {
		padding: 8px 5px !important;
		margin-right: 10px !important;
		.sidebar--text {
			max-width: 100% !important;
		}
	}
	&--nested-collapse {
		width: calc(100% - 10px);
		margin-left: 10px;
		a {
			margin-left: 50px;
			width: auto !important;
			.link-loader {
				padding-left: 0 !important;
			}
		}
	}
	&--folder-name-wrapper {
		@include flex(center, space-between, row);
		width: 90%;
		.public-project {
			position: absolute;
			top: -2px;
			left: -2px;
		}

		span {
			@include text-ellipsis;
			&.public-project-name {
				margin-left: 30px;
			}
		}
	}
	&--folder-name-button-wrapper {
		@include flex(center, flex-end, row);
		width: 35%;
	}
	&--folder-name {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&--caret {
		top: 0;
		right: 10px !important;
	}
	&--delete-or-edit-icon {
		opacity: 0;
		transform: scale(0.8);
		&:hover {
			opacity: 1 !important;
			cursor: pointer;
		}
	}
	&--link {
		&:hover {
			.sidebar--user-folder--delete-or-edit-icon {
				opacity: 0.5;
			}
		}
	}
	&--list {
		overflow: hidden !important;
	}
}
.dragging-folder {
	box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28),
		0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
	border: 1px solid $success;
	border-radius: 3px;
	background-color: $sidebarDraggingItemBackground;
}
.droppable-zone {
	min-height: 100%;
	&.main {
		width: calc(100% - 5px);
		margin: 0 auto;
	}
	&.dragging-over {
		background-color: rgba(200, 200, 200, 0.2);
		border: 1px dashed rgba(200, 200, 200, 0.2);
		border-radius: 3px;
	}
}

.info-box-wrapper-in-sidebar {
	width: auto !important;
	max-width: 200px !important;
}

.sidebar-collapse-in-dnd {
	overflow-x: hidden !important;
}
