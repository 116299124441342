@import '../../assets/style/base/colorsTheme.scss';
@import '../../assets/style/base/designTheme.scss';
@import '../../style/globalStyles.scss';

.circle-load {
	svg,
	img {
		width: 100%;
	}
}

.loader-with-text {
	&#{&} {
		margin: auto;
		@include flex(center, center, column);

		&:not(.without-border) {
			border: 1px solid gray;
			background: #f1f1f1;
			padding: 10px 17px 30px 17px;
			box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			min-width: 263px;
		}

		h4 {
			font-weight: 600;
			font-size: 14px;
			line-height: 16px;
			color: #323232;
			margin: 0;
		}

		&.without-border {
			position: relative;

			.circle-load {
				height: 80px;
			}

			h4 {
				bottom: -20px;
				width: 200px;
				position: absolute;
			}
		}
	}

	.loader {
		position: relative;
	}

	.loader::after {
		position: absolute;
		content: ' .';
		animation: dots 1.5s steps(3, end) infinite;
	}

	@keyframes dots {
		0% {
			content: ' .';
		}
		33% {
			content: ' ..';
		}
		66% {
			content: ' ...';
		}
		100% {
			content: ' .';
		}
	}
}
