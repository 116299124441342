@import '../../../../style/globalStyles.scss';
@import '../../../../assets/style/base/colorsTheme.scss';

.suggestion-parts-header {
	&--title {
		font-weight: 600;
		font-size: 20px;
		line-height: 23px;
		color: $darkTextColor;
		white-space: nowrap;
		text-align: left;

		&--advanced {
			color: $advancedText;
		}
	}

	&--remove-button {
		margin: 0 10px 0 !important;

		&--text {
			margin-left: 8px;
			height: 20px;
		}
	}

	&--search-bar {
		max-height: 32px;
		align-self: flex-end;
		padding: 8px 16px;
		border-color: $darkGrey;

		> div {
			padding: 0;

			> div {
				input {
					color: #000000 !important;
					font-size: 14px;
					font-weight: 400;
				}
			}
		}
	}
}

.parts-subheader {
	height: 55px !important;
	padding-bottom: 0 !important;

	&--hide {
		display: none;
	}
}

.cluster-section {
	&--subheader {
		&--icon {
			margin-right: 10px;
		}

		&--text {
			white-space: pre-wrap;
		}
	}

	&--button {
		&#{&} {
			text-transform: unset;
			margin: 0;
		}
	}
}

.search-again-info {
	display: flex;
	align-items: center;
	margin-bottom: 5px;

	svg {
		width: 20px;
		margin-right: 5px;
	}
}

.text {
	&-dark {
		color: #000000;
	}
}

.standard-cost {
	&--error-message {
		margin-top: 10px;
	}

	&--alert {
		width: 600px !important;

		&--body {
			&#{&} {
				max-height: 500px;
			}
		}
	}

	&--container {
		max-height: 210px;
		display: inline-block;
		overflow-y: auto;
		margin-top: 10px;

		&::-webkit-scrollbar {
			width: 7px;
			border-radius: 10px;
			background-color: hsla(218, 76, 15, 0.08);
		}

		&::-webkit-scrollbar-thumb {
			background-color: hsla(0, 0, 66, 0.7);
			border-radius: 10px;
		}
	}
}

.grid-list--tile {
	padding: 1em;
	height: auto !important;

	.grid-list-benefits {
		z-index: 101;
	}
}

.grid-list-benefits {
	display: flex;
	align-items: center;
	z-index: 101;
	min-width: fit-content;

	&--icon {
		width: 32px;
		height: 32px;
	}
}

.more-parts-button--container {
	@include flex;
	margin-bottom: 10px;
	height: unset !important;
}

.single-part-project {
	@include flex(flex-start, null);
	position: relative;
	padding-left: 30px;

	&--actions {
		@include flex(flex-start, null, column);
		margin-right: 10%;

		@include breakpoint(lg) {
			margin-right: 20%;
		}

		&--result {
			width: 100%;
			&--text {
				margin: 15px 0 10px;
				font-size: 20px;
				font-weight: 500;
			}

			> button {
				width: 100%;
				margin-top: 20px;
				margin-left: 0;
				margin-right: 0;
				text-transform: lowercase;

				span {
					display: inline-block;

					&::first-letter {
						text-transform: uppercase !important;
					}
				}
			}
		}

		&--activities {
			@include flex(null, null, column);
			bottom: 8px;
			width: 100%;

			button {
				&:first-of-type {
					margin-top: 0;
				}

				width: 100%;
			}
		}

		&--delete {
			&:hover {
				&#{&} {
					color: $text-regular-opac;
				}
			}

			&:focus {
				&#{&} {
					color: $text-regular-opac;
				}
			}

			&#{&} {
				background-color: #ffffff !important;
				color: $text-regular-opac;
				fill: $text-regular-opac;
				padding: 12px 30px;
				height: 48px;
				text-transform: capitalize;
				border-radius: 2px;
				border: 2px solid $text-regular-opac;
				margin: 10px 0 0 !important;
				order: 1;
			}

			&--icon {
				width: 20px;
				height: 20px;
			}
		}
	}

	&--part {
		min-width: 400px;
		width: 30%;
		align-self: end;

		&--card {
			margin: 0;

			&--size-wrapper {
				@include breakpoint(lg) {
					display: inline;

					&.project-result {
						display: inline-block;
					}
				}
			}
		}
	}
}

#grid-list {
	z-index: 111;

	& > div {
		overflow: visible !important; //override inline
	}

	&.graph-view {
		height: 60px !important; //override inline
		min-height: 60px !important; //override inline
	}
}

#general-parts-statistics-suggestion {
	width: calc(100% - 30px);
	margin: 0 15px;
	z-index: 0;
	overflow: visible;
	border-top: 2px solid $lightBlue;
	transition: all ease-in 500ms;

	> div:first-of-type {
		display: none;
	}

	.general-part-statistics {
		width: 100%;
		overflow: auto;

		ul {
			width: inherit;
			min-height: 400px !important; //override inline
			overflow: hidden;

			@include breakpoint(1440px) {
				min-height: 500px !important; //override inline
			}

			@include breakpoint(1680px) {
				min-height: 600px !important; //override inline
			}
		}

		ul {
			margin: 0 !important; //override inline
			box-shadow: none !important; //override inline

			li {
				width: inherit;
			}
		}

		.parts-pagination {
			min-height: 24px !important;
			margin-top: 24px !important;
		}

		&.all-inapplicable {
			min-height: 250px !important;
			height: auto;
			ul,
			li {
				min-height: 0 !important;
				height: auto !important;
			}
		}
	}
}

.all-inapplicable {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
}

.loading-wrapper {
	z-index: 1000 !important;
	width: calc(100% - 20px) !important;
}

.quick-cad-project-results--card-result {
	.multiple-parts-loading {
		height: calc(100% - 145px) !important;
	}
}

.suggestion-multiple-parts-loading {
	height: calc(100% - 235px) !important;
	bottom: 0;
	top: unset !important;
}

.main-panel-home {
	.project-analysis-block {
		position: relative;

		&__loader {
			left: -260px;
			width: 100vw !important;
		}
	}

	&.withoutMenu {
		.project-analysis-block {
			&__loader {
				left: 0;
			}
		}
	}
}

.general-part-statistics__block {
	width: 100%;

	.project-tab {
		@include flex(flex-start, flex-start, row);
		width: calc(100% - 30px);
		margin: 0 20px;
		position: relative;
		z-index: 1;

		&_item {
			padding: 12px;
			background-color: white;
			font-size: 14px;
			font-weight: 400;
			border: 2px solid white;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			margin-right: 8px;
			position: relative;

			&:hover {
				cursor: pointer;
				color: $lightBlue;
			}

			&.disabled {
				pointer-events: none;
				background-color: darken(white, 10);
				border-color: darken(white, 10);
			}

			&.active {
				color: $lightBlue;
				border-color: $lightBlue;
				font-weight: 500;

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					height: 8px;
					background-color: white;
					bottom: -7px;
					left: 0;
					z-index: 1;
				}
			}
		}
	}
}

.failed-parts-warning {
	&--wrapper {
		@include flex(center, center, row);
		@include font-builder(16px, 400);
		gap: 15px;
		width: fit-content;
		height: 70px;
		padding: 10px 60px;
		border-radius: 8px;
		margin: 10px auto;
		background-color: $whiteBackground;
		color: $blackColor;
		box-shadow: 0px 4px 20px 0 rgba(0, 0, 0, 0.14);
		svg {
			width: 24px;
		}
	}
	&--remove-icon {
		cursor: pointer;
	}
}

.project-suggestion-filter {
	width: 100%;
	margin: 15px 0;

	&__block {
		width: 100%;
		height: 155px;
		white-space: nowrap;
		display: flex;
		overflow-x: auto;
		white-space: nowrap;
		gap: 16px;
		padding: 5px 0;
		@include styled-scroll();

		.filter-item {
			border: 1px solid $darkGrey;
			padding: 12px;
			background-color: #ffffff;
			border-radius: 8px;
			max-width: 350px;
			min-width: 230px;
			box-sizing: border-box;
			padding: 10px;
			height: 134px;
			flex: 1;
			container-name: filter;
			container-type: size;

			&:hover {
				background-color: lighten($lightBlue, 46) !important;
				cursor: pointer;
				box-shadow: 0 0px 0px 0 rgba(darken($lightBlue, 5), 0.14),
					0 0px 2px 1px rgba(darken($lightBlue, 5), 0.2),
					0 1px 14px 0 rgba(darken($lightBlue, 5), 0.12);
			}

			&:last-of-type {
				margin-right: 0;
			}

			&.active {
				background-color: lighten($lightBlue, 44) !important;
				border-color: $lightBlue;
			}

			&.locked {
				background-color: rgba($unlock-background, 0.4) !important;
				border-color: $unlock-background;
				border-width: 2px;
				cursor: initial;

				&:hover {
					box-shadow: none;
				}

				button {
					padding: 2px 5px;
					font-size: 14px;
					&:hover {
						cursor: pointer;
					}
				}

				svg {
					height: 16px;
					width: 20px;
					margin-right: 5px;
					&:hover {
						cursor: initial;
					}
				}

				title > div {
					height: 20px;
				}
			}

			.title {
				@include flex(center, space-between);
				color: $text-regular;
				font-size: 13px;
				font-weight: 500;
				line-height: 15px;
				height: 20px;
				white-space: break-spaces;

				@include breakpoint(lg) {
					font-size: 14px;
				}

				svg {
					&:hover {
						fill: $lightBlue;
						transform: scale(1.05);
					}
				}

				> div {
					svg {
						fill: $darkGrey;
						width: 15px;
						margin-left: 10px;
					}
				}
			}

			.body {
				height: calc(100% - 15px);
				@include flex(center, center, column);

				.statistic {
					margin-top: 5px;
					@include flex(center, center);

					.text {
						font-size: 12px;
						font-weight: 400;
						color: $darkTextColor;
						line-height: 12px;
						max-width: 40%;
						text-wrap: balance;
					}

					.number {
						font-size: 35px;
						color: $darkTextColor;
						font-weight: 600;
						margin-right: 4px;

						@include breakpoint(1800px) {
							font-size: 40px;
						}
					}
				}

				.sub-text {
					color: $darkGrey;
					font-size: 12px;
					font-weight: 400;
					width: 100%;
					white-space: pre-line;
					text-align: center;
					position: relative;
					margin-top: 12px;

					.text {
						font-size: 12px;
						color: $darkTextColor;
						font-weight: 500;
						padding: 0 5px;
						text-decoration: underline;
					}

					div {
						display: ruby;

						> div {
							white-space: nowrap;
							padding: 0;
						}
					}

					svg {
						height: 15px;
						position: relative;
						top: 3px;

						&:hover {
							* {
								fill: $lightBlue;
							}
						}
					}
				}
			}
		}

		.disabled-public {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}

.suggestion-card-footer {
	width: 100px;
	display: inline-block;
	white-space: nowrap;
	@include text-ellipsis();

	&.full {
		width: 70%;
	}
}

.challenge-filter {
	&--radio-group {
		@include flex(flex-start, flex-start, row);
		width: 100%;
		margin: 15px 0;
	}

	&--input {
		span {
			text-transform: lowercase;
			margin-left: 5px;
		}
	}

	.challenge-radio {
		margin-bottom: 5px;
		> span:first-of-type {
			margin-right: 10px;
		}
	}
}

@container filter (max-width: 245px) {
	.sub-text {
		margin-top: 5px !important;
	}
}
