@import '../../../../../style/globalStyles.scss';
@import '../../../../../assets/style/base/colorsTheme.scss';

span:has(+ .suggestion-parts-list--part-name) {
	font-size: 11px;
}

.suggestion-parts-list {
	&--part-name {
		font-weight: 500;
		font-size: 14px;
		@include text-ellipsis();
		width: 100%;
	}

	&--wrapper {
		width: 100%;
		padding: 0 10px 0 0;
		min-height: 386px;
		text-align: left;
		&.empty {
			padding: 0;
			svg {
				width: 148px;
				margin-bottom: 18px;
			}

			.text {
				max-width: 500px;
				font-weight: 600;
				font-size: 20px;
				text-align: center;
			}
		}
	}

	&--header {
		display: grid;
		width: 100%;
		grid-template-columns:
			94px minmax(150px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)
			minmax(100px, 1fr) minmax(200px, 1fr) minmax(150px, 1fr) 70px;

		&--item {
			@include flex(center, flex-start, row);
			padding-left: 16px;
			font-weight: 500;
			gap: 5px;
			white-space: nowrap;

			svg {
				width: 15px;
				position: relative;

				&.desc {
					path#bottom {
						fill: $lightBlue;
					}
				}

				&.asc {
					path#top {
						fill: $lightBlue;
					}
				}

				&:hover {
					cursor: pointer;
					path {
						fill: $lightBlue;
					}
				}
			}
		}
		&--info-icon {
			width: 14px;
		}
	}
	&--body {
		width: 100%;
	}
	&--part-row {
		width: 100%;
		display: grid;
		grid-template-columns:
			94px minmax(150px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)
			minmax(100px, 1fr) minmax(200px, 1fr) minmax(150px, 1fr) 70px;
		align-items: center;
		border-radius: 8px;
		box-shadow: 0 0 10px $cardShadow;
		height: 60px;
		margin: 16px 0;
		cursor: pointer;

		.text-ellipsis {
			width: 100%;
			@include text-ellipsis();
		}

		&:hover {
			background-color: $hoverPartColor;
			.part-actions--remove {
				opacity: 1;
			}
		}

		.project-results--label--title--icon {
			width: 12px;

			.icons--benefits--fill {
				fill: $success;
			}

			#dollar-symbol-copy {
				path {
					fill: #ffffff;
				}
			}
		}

		.pdf-icon {
			position: absolute;
			right: 0px;
			bottom: 0px;
			width: 25px;
			background-color: $lightBlue;
			fill: #ffffff;
			margin: 0;
			padding: 2px;
			border-top-left-radius: 4px;
		}

		svg {
			margin-right: 5px;
		}

		&--extra-reasons {
			background-color: $lightGrey;
			margin-left: 5px;
			padding: 2px 6px;
			border-radius: 4px;
		}

		&--cell-img {
			width: 90px;
			height: 60px;
			border-radius: 8px 0 0 8px;
			background-color: $partBackground;
			padding: 5px;
			position: relative;

			&.drawing {
				padding: 0;
				background-color: transparent;
			}
			.cell-fallback-img {
				border-radius: 8px 0 0 8px;
				svg {
					transform: scale(1.5);
				}
			}
			.cell-img {
				border-radius: 8px 0 0 8px;
				height: auto;
				width: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
			}
		}
		&--cell-text {
			@include flex(center, flex-start, row);
			@include text-ellipsis();
			white-space: nowrap;
			padding-left: 16px;
			font-weight: 400;
			span,
			div {
				@include text-ellipsis();
				z-index: 1;
			}
			&.pure-text {
				display: inline-block;
			}
			&.with-info {
				@include flex(center, flex-start, row);
				gap: 5px;
			}
			&.wrap-pretty {
				width: 60%;
				text-wrap: pretty;
			}
			&.star-delete {
				padding-right: 16px;
				padding-left: 0;
				.part-actions {
					position: relative;
					top: 0;
					left: 0;
					width: auto;
					gap: 5px;

					&:first-of-type {
						margin-left: 10px;
					}
				}
			}
			.card--result {
				&-label-text {
					margin-right: 12px;
				}
			}
		}
		&--three-d {
			width: auto;
		}
		.defaultText {
			font-style: italic;
			padding-right: 2px;
		}
	}
}

.additional-parts {
	&-consolidation,
	&-weight-reduction {
		color: #000000;

		&:not(&.coloring) {
			@include text-ellipsis();
			white-space: nowrap;
			width: 100%;
		}

		&.coloring {
			border: 1px solid;
			border-radius: 100px;
			padding: 4px 8px 4px 8px;
		}
	}

	&-consolidation {
		&.coloring {
			background-color: #eae5fd;
			border-color: #7653ed;
			color: #7653ed;
		}
	}
	&-weight-reduction {
		&.coloring {
			background-color: #e6f7ff;
			border-color: #0050b3;
			color: #0050b3;
		}
	}
}
.info-box-save-part {
	width: auto !important;
}
